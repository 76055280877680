import axios from 'axios'

export const baseURL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  // : 'http://127.0.0.1:8880/backend'
  : 'https://api.dasongnet.com/graham/backend'
console.log('baseURL', baseURL)

class Client {
  jwt = null
  u = null

  constructor() {
    this.http = axios.create({baseURL})
    this.http.interceptors.response.use((resp) => {
      return resp
    }, (error) => {
      let obj = error
      if (obj.response) {
        obj = obj.response
        if (obj.status === 403) {
          window.sessionStorage.clear()
          window.location.reload()
        }
      }
      if (obj.data) {
        obj = obj.data
      }
      if (obj.message) {
        obj = obj.message
      }
      console.log('[Error]', obj)
      const msg = typeof (obj) === 'string' ? obj : JSON.stringify(obj)
      alert(`msg: ${msg}`)
      return Promise.reject(error)
    })
  }

  getJwt() {
    return this.jwt
  }

  setJwt(jwt) {
    console.log('setJwt', jwt)
    this.jwt = jwt
    this.http.defaults.headers['Authorization'] = `Bearer ${jwt}`
  }

  getMembers(params) {
    return this.http.get('/members', {params})
  }

  getMemberJwt(phoneNumber) {
    return this.http.get(`/members/${phoneNumber}/jwt`)
  }

  addMember(params) {
    return this.http.post('/members', params)
  }

  updateMember(id, params) {
    return this.http.patch(`/members/${id}`, params)
  }

  rmMember(id) {
    return this.http.delete(`/members/${id}`)
  }

  getProducts(params) {
    return this.http.get('/products', {params})
  }

  getSubs(params, responseType = null) {
    return this.http.get('/subs', {params, responseType})
  }

  addSub(params) {
    return this.http.post('/subs', params)
  }

  updateSub(id, params) {
    return this.http.patch(`/subs/${id}`, params)
  }

  updateSubPhoneNumber(id) {
    return this.http.patch(`/subs/${id}/phoneNumber`)
  }

  rmSub(id) {
    return this.http.delete(`/subs/${id}`)
  }

  getStats(params) {
    return this.http.get('/stats', {params})
  }

  queryWoreadSubs(params) {
    return this.http.post('/woread/subs', params)
  }
}

const client = new Client()
export default client
