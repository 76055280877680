import {Component} from 'react'
import {Space, SpinLoading} from "antd-mobile";

class Loading extends Component {
  render() {
    return (
      <div style={{margin: '8px'}}>
        <SpinLoading color='primary'/>
      </div>
    )
  }
}

export default Loading
