import {Web3} from "web3";
import Token from "./Token";

const URL = 'https://mainnet.infura.io/v3/01d250217e914b68adf1b039e9847e95'
const ADDRESS = '0xdac17f958d2ee523a2206206994597c13d831ec7'
const INTERFACE = [
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
]

class TetherToken extends Token {
  web3 = null
  api = null

  constructor() {
    super();
    this.web3 = new Web3(new Web3.providers.HttpProvider(URL))
    this.api = new this.web3.eth.Contract(INTERFACE, ADDRESS)
  }

  async balanceOf(address) {
    const r = await this.api.methods.balanceOf(address).call()
    return this.web3.utils.fromWei(r, "mwei")
  }
}

const USDT = new TetherToken()
export default USDT
