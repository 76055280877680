import React, {Component} from 'react'
import Loading from './Loading'
import client from './Client'
import {withAuth0} from '@auth0/auth0-react'
import {Button} from "antd-mobile";

class Guard extends Component {
  state = {
    jwt: null,
  }

  componentDidMount() {
    console.log('window.location', window.location)
    this.login()
  }

  async login() {
    const {auth0} = this.props
    // console.log('auth0', auth0)
    const {
      getAccessTokenSilently,
      user,
      isAuthenticated,
      isLoading,
      error,
    } = auth0
    console.log('isAuthenticated', isAuthenticated, 'user', user, 'isLoading', isLoading)
    if (isLoading) {
      setTimeout(this.login.bind(this), 500)
    } else if (error) {
      alert(error)
    } else if (isAuthenticated) {
      try {
        const jwt = await getAccessTokenSilently()
        client.setJwt(jwt)
        this.setState({jwt})
      } catch (e) {
        console.log(e)
      }
    }
  }

  render() {
    const {children, auth0} = this.props
    const {jwt} = this.state
    if (jwt) {
      return children
    }
    const {loginWithRedirect, isLoading} = auth0
    return (
      <div>
        {
          isLoading ?
            <Loading/>
            :
            <div style={{margin: '8px'}}>
              <p style={{textAlign: 'center', marginTop: '50%'}}>
                <img
                  src="https://zk-snark.uk.to/img/aleo-logos/PNG/primary-logo-dark.png"
                  width="70%"/>
              </p>
              <p>
                <Button
                  color="primary"
                  block
                  onClick={loginWithRedirect.bind(this, {ui_locales: 'zh-CN'})}>
                  Login
                </Button>
              </p>
            </div>
        }
      </div>
    )
  }

}

export default withAuth0(Guard)
