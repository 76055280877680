import React from "react"
import {Button, List, Swiper} from "antd-mobile"

const ITEMS = [
  {
    prefix: "1",
    description: "545.32B",
    extra: "$27,958.09",
    chain: 'BTC',
  },
  {
    prefix: "2",
    description: "196.31B",
    extra: "$1,633.96",
    chain: 'ETH',
  }
]

const URLS = [
  'https://images.ctfassets.net/qyo46trxl4dy/60ViIqjXJz80AUZEKbQj6v/7cb13da5f24028e71e10a61375cf59af/zkEVM_vs_zkVM_Identity_blog_header.png?w=1440&h=810&q=70&fm=webp',
  'https://images.ctfassets.net/qyo46trxl4dy/5jppINGnn8uagmo36CwMGy/1bdf9f817cb733df0273b954726a623b/646b82175f7ff1456cddaedf_aleo-off-chain-infrastructure-blog-header__4_-min.png?w=1440&h=737&q=70&fm=webp',
  'https://images.ctfassets.net/qyo46trxl4dy/UeF9kl1CsZkvyjb52Lfzf/511e949c1043d7ac30dd56c50786ff98/21-loan-decisions.png?w=1440&h=810&q=70&fm=webp',
]

class Dash extends React.Component {
  render() {
    return (
      <div>
        <Swiper>
          {
            URLS.map((url, idx) => (
              <Swiper.Item key={idx}>
                <img
                  src={url}
                  width="100%"/>
              </Swiper.Item>
            ))
          }
        </Swiper>
        <div style={{margin: '12px'}}>
          <h2>Zero-knowledge with uncompromising speed and privacy</h2>
          <p>Build full-stack, private applications <br />with the power of ZK on layer 1</p>
          <p>
            <Button color="primary" block>Start Building</Button>
          </p>
        </div>
        <div style={{margin: '12px'}}>
          <h2>This is zero-knowledge by design.</h2>
          <p>Decentralization, scalability, and privacy aren’t mutually exclusive anymore.</p>
          <p>True ZK should provide all three. Aleo is the first place that brings all the power of ZK under one roof.</p>
        </div>
      </div>
    )
  }
}

export default Dash
