import './App.css'
import {HashRouter as Router, Redirect, Route, Switch,} from 'react-router-dom'
import Dash from "./pages/Dash"
import Wallets from "./pages/Wallets"
import Wallet from "./pages/Wallet"
import React from "react"
import Guard from "./components/Guard";
import {Auth0Provider} from "@auth0/auth0-react";

function App() {
  return (
    <Auth0Provider
      domain="zk-snark.us.auth0.com"
      clientId="ByavRGWOzjIWMAb44uhnAj5g5vX2WaUt"
      audience="https://zk-snark.uk.to/api/"
      redirectUri={window.location.href}>
      <div className="app">
        <Guard>
          <Router>
            <Switch>
              <Route path="/dash" component={Dash} exact/>
              <Route path="/wallets" component={Wallets} exact/>
              <Route path="/wallets/:id" component={Wallet}/>
              <Route path="/" exact>
                <Redirect to="/wallets"/>
              </Route>
            </Switch>
          </Router>
        </Guard>
      </div>
    </Auth0Provider>
  );
}

export default App;
