import React from "react"
import {Avatar, Tag} from "antd-mobile"
import {Web3} from "web3";

const URL = 'https://mainnet.infura.io/v3/01d250217e914b68adf1b039e9847e95'
const USDT_CONTRACT_ADDRESS = '0xdac17f958d2ee523a2206206994597c13d831ec7'

class Wallet extends React.Component {
  componentDidMount() {
    // this.getBalance()
  }

  async getBalance() {
    console.log('getBalance')
    const web3 = new Web3(new Web3.providers.HttpProvider(URL))
    const balanceOfABI = [
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "balance",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ];
    const usdtContract = new web3.eth.Contract(
      balanceOfABI,
      USDT_CONTRACT_ADDRESS
    );
    const ethAddress = '0x95a9bd206ae52c4ba8eecfc93d18eacdd41c88cc'
    // console.log('usdtContract', usdtContract)
    /*
    usdtContract.methods.balanceOf(ethAddress).call((error, balance) => {
      console.log('balanceOf')
      console.log('error', error, 'balance', balance)
      if (!error) {
        const balanceInWei = balance.toString();
        const balanceInUSDT = web3.utils.fromWei(balanceInWei, 'ether');
        this.setState({balance: balanceInUSDT});
      }
    });
    */
    const r = await usdtContract.methods.balanceOf(ethAddress).call()
    console.log('r', typeof r, r)
    const resultInEther = web3.utils.fromWei(r, "mwei");
    console.log('resultInEther', resultInEther)
  }

  render() {
    return (
      <div style={{textAlign: 'center'}}>
        <div>
          <Avatar
            src="https://dynamic-assets.coinbase.com/41f6a93a3a222078c939115fc304a67c384886b7a9e6c15dcbfa6519dc45f6bb4a586e9c48535d099efa596dbf8a9dd72b05815bcd32ac650c50abb5391a5bd0/asset_icons/1f8489bb280fb0a0fd643c1161312ba49655040e9aaaced5f9ad3eeaf868eadc.png"
            style={{margin: '30px auto'}}/>
        </div>
        <div>
          <p>
            <Tag>USDT</Tag>
          </p>
          <p>
            <code>
              0x243d04f10e6150E626a3736102AFe7f967BDE767
            </code>
          </p>
          <p>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAAGQAQAAAACoxAthAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAd2KE6QAAAAJcEhZcwAAFxEAABcRAcom8z8AAAMGSURBVHja7Zs7kqtADEXlcuBwlsBSWBosjaV4CYQELuvp2928z1RNhvWuAg/V04eEi34tiH9sBAQIkBLITmG8Ed1Yfu9MCx+Ut3vqQtgXkCsjD/vHS3fIPpr3O/OqtF/yUzbQ7DSQcshbldARl8mhiNyIaDruSsvVAqQusk2CLBs1R7HJquwjIP8BYraqYGRBVh0RA/IpSPP8T437Dk4cq7Lvu2AB5MORnvVN4u5XDfa6zwUjC+b5/5UoAvlsZLSj5/xsiKnkcdoDpBby1thuFZ+oZNF9Lhj1EW/N+iwR0NuFuwByYYT1hZ9tn4Z5feEp/ILdTmj1CxMDqYVo3F+pC8bfcH/hrXi36D/v6QGAlENMCTTxKf1PMal2LAicBAOkAqIrSzbubq6P+KckgO9WvA+CAXJNxIr3yTw/ewIvD3sl3SGP/J3l2y6l3do68ECKILbPi7Mpe7Dp/9U8KmSHB0gxxPJ6/Wtx3zy/2B+CefApUQRSBFFTH8GW3ZsoFuvhGUIRN2is+IBcEjnIa7ZWvrXDlLDjztmSAVIM2c3n+5EpR/GuvyaYl6rk4acww/kLkCoId5egPsJP31rjjh0R+rduD5ASiAd0lY3vYD9/yVmqFIy6CyDXRqJ453jh15yG8h5sVvP29MdgAaQM4ll7vPuqnZiEtIrdp6GYx4oPSBFkbwHdiveFm/ksnDfuqKf/QEoh5gfaJGScvmUCqJevU6MeyFURzoCuA+3ZjLcerCKtD8PD0wdSBtnt9HRvgxJb184cAxbp+cfhSSAlkJb1aQ9W870vn2K3uH8/9WAZSDWkz8Fu6g3i9G3ys/Ns2pC35JmBXBjptkXrTa9tqDU6dNqDHTI9IIWQPRXh+zwItESglW8WIICUQ7q7oDYo0Qbao3xrXRwg1ZDhq7Ts1VrF1wTjgroNcR/ItRGbZPW4H5+XemH/6G6AgVRFfB4me7C3/BhpirTgXPEBKYKk5/cRmllFkXPMw8fFY68PSBmkZ308BIsjlxf+6/kLkOshPzUgQIAUQH4BgD64u9uCGhUAAAAASUVORK5CYII="
              width="60%"/>
          </p>
        </div>
      </div>
    )
  }
}

export default Wallet
