import React from "react"
import {AutoCenter, Avatar, Button, CapsuleTabs, List, Loading, Modal, NavBar, Tag} from "antd-mobile";
import {DownOutline, SendOutline} from "antd-mobile-icons";
import './Wallets.css'
import {QRCodeSVG} from "qrcode.react";
import {withAuth0} from "@auth0/auth0-react";
import USDT from "../components/TetherToken";

const IMG_URL = 'https://dynamic-assets.coinbase.com/41f6a93a3a222078c939115fc304a67c384886b7a9e6c15dcbfa6519dc45f6bb4a586e9c48535d099efa596dbf8a9dd72b05815bcd32ac650c50abb5391a5bd0/asset_icons/1f8489bb280fb0a0fd643c1161312ba49655040e9aaaced5f9ad3eeaf868eadc.png'

class Wallets extends React.Component {
  state = {
    u: null,
    a: null,
    b: null,
    paneIdx: -1,
  }

  componentDidMount() {
    this.getToken()
  }

  async getToken() {
    const a = '0x95a9bd206ae52c4ba8eecfc93d18eacdd41c88cc'
    const b = await USDT.balanceOf(a)
    console.log('a', a, 'b', b)
    this.setState({a, b})
  }

  render() {
    const {auth0} = this.props
    const {user: u, logout} = auth0
    const {a, b} = this.state
    if (!u || !b) {
      return <Loading/>
    }
    return (
      <div>
        <div className="header">
          <NavBar
            back={null}
            left={(
              <Avatar src={u.picture}/>
            )}
            right={(
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  logout({logoutParams: {returnTo: window.location.origin}})
                }}>
                Logout
              </Button>
            )}>
            WALLET
          </NavBar>
        </div>
        <div className="accounts">
          <List>
            <List.Item
              prefix={(
                <Avatar src={IMG_URL}/>
              )}
              extra={(
                <DownOutline/>
              )}>
              Account 1
            </List.Item>
            <List.Item
              prefix={(
                <strong>Address</strong>
              )}>
              <Tag color="success">
                {a.slice(0, 16)}...{a.slice(-4)}
                &nbsp;
                <SendOutline/>
              </Tag>
            </List.Item>
          </List>
        </div>
        <CapsuleTabs>
          <CapsuleTabs.Tab title='Tokens' key='Tokens'/>
          <CapsuleTabs.Tab title='NFTs' key='NFTs' disabled/>
        </CapsuleTabs>
        <List>
          <List.Item
            extra={(
              <Button>
                Portfolio
                &nbsp;
                <SendOutline/>
              </Button>
            )}>
            <h1>${b}</h1>
          </List.Item>
          <List.Item
            prefix={(<Avatar src={IMG_URL}/>)}
            description={`${b} USDT`}>
            Tether
          </List.Item>
        </List>
        <div style={{margin: '12px'}}>
          <AutoCenter>
            <p>
              Add crypto to get started
            </p>
          </AutoCenter>
          <Button
            color="primary"
            block
            shape='rounded'
            onClick={() => {
              Modal.alert({
                content: (
                  <div>
                    <QRCodeSVG
                      value={a}
                      size="100%"
                      level="H"/>
                  </div>
                )
              })
            }}>
            Buy
          </Button>
        </div>
        <AutoCenter>
          Don't see your token? <a href="#">Import tokens</a>
        </AutoCenter>
      </div>
    )
  }
}

export default withAuth0(Wallets)
